<template>
  <div>
    <Container v-if="isshow" :mainpagedata="mainpagedata" :mydefault="mydefault"></Container>
  </div>
</template>

<script>
import Container from '@/components/Container.vue'
import {defineComponent} from "vue";
import * as echarts from "echarts";

export default defineComponent({
  name: 'userlist',
  data() {
    return {
      mainpagedata: {
        //特殊页面
        showpages: { //判断pages页面，常规通用组件页面显示pages，特殊页面显示special
          isshowpages: false,      //显示pages的内容
          isshowspecial: true,   //显示special的内容
          mycontrol: 1, //当该页面为special页面内容时，用数字控制页面-详情内容显示
          content: {
            echarts: {
              isshow: {
                columnisshow: true,          //柱状图
                mypanelisshow: true,         //仪表盘
                filletcakeisshow: true,      //饼状图-圆角
                customcakeisshow: true,      //饼状图-自定义样式
                linegradualisshow: true,     //折现图-渐变堆叠
              },
              data: {
                column: [
                  {
                    idname: 'column',
                    width: '80%',
                    height: '400px',
                    text: '设备在线时长',   //柱状图表-标题
                    subtext: '在线时长（分钟）', //柱状图表-Y轴名
                    subtextx: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], //柱状图表-X轴名[数组string类型]
                    subdata: [9.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3], //柱状图表-data数据[数组int类型]
                    subcolor: '#002EB7',
                    content: {}
                  },
                  {
                    idname: 'column1',
                    width: '50%',
                    height: '400px',
                    text: '设备在线',
                    subtext: '在线时长（小时）',
                    subtextx: ['Jan123123', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], //柱状图表-X轴名[数组string类型]
                    subdata: [9.0, 42.9, 7.0, 23.2, 25.6, 76.7, 35.6, 22.2, 32.6, 20.0, 6.4, 3.3], //柱状图表-data数据[数组int类型]
                    subcolor: '#002EB7',
                    content: {}
                  },
                  {
                    idname: 'column1',
                    width: '50%',
                    height: '400px',
                    text: '设备在线',
                    subtext: '在线时长（小时）',
                    subtextx: ['Jan123123', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], //柱状图表-X轴名[数组string类型]
                    subdata: [9.0, 42.9, 7.0, 23.2, 25.6, 76.7, 35.6, 22.2, 32.6, 20.0, 6.4, 3.3], //柱状图表-data数据[数组int类型]
                    subcolor: '#002EB7',
                    content: {}
                  }
                ],
                mypanel: [
                  {
                    idname: 'mypanel',
                    width: '30%',
                    height: '400px',
                    subcolor: '#002EB7',
                    data: '25'
                  },
                  {
                    idname: 'mypanel1',
                    width: '100%',
                    height: '400px',
                    subcolor: '#f34c4c',
                    data: '60'
                  }
                ],
                filletcake: [
                  {
                    idname: 'filletcake',
                    width: '30%',
                    height: '400px',
                    data: [
                      {value: 1048, name: 'Search Engine'},
                      {value: 735, name: 'Direct'},
                      {value: 580, name: 'Email'},
                      {value: 484, name: 'Union Ads'},
                      {value: 300, name: 'Video Ads'}
                    ]
                  },
                  {
                    idname: 'filletcake2',
                    width: '100%',
                    height: '400px',
                    data: [
                      {value: 4048, name: 'Search Engine'},
                      {value: 735, name: 'Direct'},
                      {value: 580, name: 'Email'},
                      {value: 484, name: 'Union Ads'},
                    ]
                  },
                ],
                customcake: [
                  {
                    idname: 'customcake',
                    width: '100%',
                    height: '400px',
                    text: '订单金额分布情况',  //饼状图-自定义-标题
                    textcolor: '#002EB7',
                    subcolor: '#002EB7',
                    labelcolor: '#002EB7',
                    data: [
                      {value: 380, name: 'Direct'},
                      {value: 310, name: 'Email'},
                      {value: 274, name: 'Union Ads'},
                      {value: 235, name: 'Video Ads'},
                      {value: 400, name: 'Search Engine'}
                    ]
                  },
                  {
                    idname: 'customcake1',
                    width: '50%',
                    height: '400px',
                    text: '订单金额分布情况',  //饼状图-自定义-标题
                    textcolor: '#002EB7',
                    subcolor: '#002EB7',
                    labelcolor: '#002EB7',
                    data: [
                      {value: 380, name: 'Direct'},
                      {value: 310, name: 'Email'},
                      {value: 274, name: 'Union Ads'},
                      {value: 235, name: 'Video Ads'},
                      {value: 400, name: 'Search Engine'}
                    ]
                  },
                  {
                    idname: 'customcake2',
                    width: '50%',
                    height: '400px',
                    text: '订单金额分布情况',  //饼状图-自定义-标题
                    textcolor: '#002EB7',
                    subcolor: '#002EB7',
                    labelcolor: '#002EB7',
                    data: [
                      {value: 380, name: 'Direct'},
                      {value: 310, name: 'Email'},
                      {value: 274, name: 'Union Ads'},
                      {value: 235, name: 'Video Ads'},
                      {value: 400, name: 'Search Engine'}
                    ]
                  },
                ],
                linegradual: [
                  {
                    idname: 'linegradual',
                    width: '100%',
                    height: '400px',
                    text: '折线-渐变堆叠', //折线图-标题
                    maincolor: ['#80FFA5', '#00DDFF'],  //定义主色调，且有几个模块，定义几个，[数组string类型]
                    legenddata: ['Line 1', 'Line 2'],  //定义模块，[数组string类型]

                    subtextx: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], //图表X轴-标题
                    gridleft: '10%',  //图表距离左侧距离
                    gridright: '10%', //图表距离右侧距离
                    gridbottom: '10%',//图表距离底部距离
                    data: [
                      {
                        name: 'Line 1', //与上方模块定义的名对应
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                          width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0,
                              color: 'rgba(128, 255, 165)'  //渐变的颜色1
                            },
                            {
                              offset: 1,
                              color: 'rgba(1, 191, 236)'  //渐变的颜色2
                            }
                          ])
                        },
                        emphasis: {
                          focus: 'series'
                        },
                        data: [140, 232, 101, 264, 90, 340, 250] //对应图表X轴上的数据
                      },
                      {
                        name: 'Line 2',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                          width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0,
                              color: 'rgba(0, 221, 255)'
                            },
                            {
                              offset: 1,
                              color: 'rgba(77, 119, 255)'
                            }
                          ])
                        },
                        emphasis: {
                          focus: 'series'
                        },
                        data: [120, 282, 111, 234, 220, 340, 310]
                      }
                    ]
                  },
                  {
                    idname: 'linegradual1',
                    width: '100%',
                    height: '400px',
                    text: '折线-渐变堆叠', //折线图-标题
                    maincolor: ['#80FFA5', '#00DDFF'],  //定义主色调，且有几个模块，定义几个，[数组string类型]
                    legenddata: ['Line 1', 'Line 2'],  //定义模块，[数组string类型]

                    subtextx: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], //图表X轴-标题
                    gridleft: '2%',  //图表距离左侧距离
                    gridright: '2%', //图表距离右侧距离
                    gridbottom: '10%',//图表距离底部距离
                    data: [
                      {
                        name: 'Line 1', //与上方模块定义的名对应
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                          width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0,
                              color: 'rgba(128, 255, 165)'  //渐变的颜色1
                            },
                            {
                              offset: 1,
                              color: 'rgba(1, 191, 236)'  //渐变的颜色2
                            }
                          ])
                        },
                        emphasis: {
                          focus: 'series'
                        },
                        data: [140, 232, 101, 264, 90, 340, 250] //对应图表X轴上的数据
                      },
                      {
                        name: 'Line 2',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                          width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0,
                              color: 'rgba(0, 221, 255)'
                            },
                            {
                              offset: 1,
                              color: 'rgba(77, 119, 255)'
                            }
                          ])
                        },
                        emphasis: {
                          focus: 'series'
                        },
                        data: [120, 282, 111, 234, 220, 340, 310]
                      }
                    ]
                  },
                ]
              }, //不同的图表数据

            },  //关于echarts图的数据
            details: {
              showstatus: 1, //确定样式模块
              title: '订单详情',
              button: {
                isshow: false,
                name: '查看微信投诉详情'
              },
              content: [
                {
                  name: '订单编号:',
                  value: '1598239191912'
                },
                {
                  name: '订单状态:',
                  value: '已完成',
                  jumpbt:'查看状态'
                },
                {
                  name: '打印状态:',
                  value: '待打印'
                },
                {
                  name: '订单金额:',
                  value: '300.12'
                },
                {
                  name: '实付金额:',
                  value: '300.12'
                }
              ]

            },//关于详情信息的组件
            imgesdata: {
              showstatus: 2,
              content: [
                {
                  url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                  preview: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']
                },
                {
                  url: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
                  preview: ['https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg']
                },
                {
                  url: 'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
                  preview: ['https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg']
                },
                {
                  url: 'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
                  preview: ['https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg']
                }
              ]
            },//关于图片展示的组件
            input: {
              myinputishow: true, //高级筛选-input单框
              myinput: {
                inputwidth: '100%',
                input: [
                  {
                    ismust: false, //是否必填，true表示必填
                    title: '输入搜索：',
                    placeholder: '用户ID/用户昵称/手机号',
                    name: 'keywords',
                    content: '',
                    textarea: 'textarea',
                    textareaheight: 3
                  }
                ]
              },
            }
          },  //特殊页面中模块的配置数据
        },
      }, // 当前Pagedata所需的data数据与控制
      mydefault: {
        myheadactive: "a0",    //该字段为string类型，是头部导航key值
        myasideactive: "3",  //该字段为string类型，是子页面的id
      }, //本页面的导航默认值-逆向绑定（可优化-通过当前页面的path值，对数据进行筛选对比，将值自动赋值，避免修改路由之后出现页面&导航不对应状况）
      mytoken: localStorage.getItem('token'),  //获取存储的token
      isshow: true  //用于判断页面模块是否存在

    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    }
  },
  components: {
    Container,
  }
})
</script>

<style scoped>

</style>
